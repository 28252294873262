
#tour-hero {
  color: #fff;
}

#tour-hero .bg-img-wrapper {
  height: 500px;
  width: 100vw;
  position: absolute;
}

.tour-cards {
  margin-top: 200px;
}
.tour-card .content {
  background: #fff;
}

.tour-card img {
  width: 100%;
}
.tour-card time, .tour-card address {
  margin-bottom: 20px;
  display: inline-block
}
.tour-cards h1 {
  font-weight: 800;
}
.tour-card h6 {
  text-transform: uppercase;
  font-size: 0.8rem;
}

.tour-card a.cta {
  width: 100%;
  margin-top: 20px;
  font-size: 1.3rem;
}
