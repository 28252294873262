.get-scythe-section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.get-scythe-hero {
  color: #fff;
  min-height: 100vh;
  width: 100vw;

  background-image: url('/img/get-scythe-hero.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10%;
}

.get-scythe-hero p {
  font-size: 18px;
  line-height: 160%;
  margin-top: 20px;
  margin-bottom: 30px;

}

.get-scythe-hero .cta-btn {
  /* text-transform: uppercase; */
  padding: 15px 30px;
  font-size: 18px;
  width: 250px;
}

/* .get-scythe-hero::after {
  content: "More Information";
  color: #fff;
  position: absolute;
  bottom: 25px;
  background: url("/img/keep-scrolling.png") bottom center no-repeat;
  padding-bottom: 20px;
  opacity: 0.6;
  text-align: center;
  width: 100%;
} */


.get-scythe-hero::after {
  content: "More Information";
  color: #fff;
  position: relative;
  background:  url("/img/keep-scrolling.png") center 35px no-repeat;
  padding-bottom: 50px;
  opacity: 0.6;
  text-align: center;
  width: 100vw;
  display: block;
}


.get-scythe-hero h1 {
  font-size: 4rem;
}

.get-scythe-hero ul.features {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 150px;
}

.callout h2 {
  display: flex;
  flex-direction: row;
  font-weight: 300;
  line-height: 45px;
  overflow: none;
}



@media only screen and (max-width: 768px) {
  .get-scythe-hero .display-3 {
    font-size: 32px;
    margin-top: 100px;
    text-align: center;
    letter-spacing: 0;
  }
  .get-scythe-hero .cta-btn {
    text-align: center;
    width: 100%;
    margin-top: 200px;

  }

  .get-scythe-hero ul.features {
    margin-top: 50px;
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .callout h2 {
    font-size: 22px;
  }

  .callout h2.spendless {
    line-height: 1.5rem;
    margin-top: 30px;
  }

  .prices h6.ourgoal strong {
    display: block;
  }

  .callout p {
    margin-top: 0;
    font-size: 16px;
  }
  .get-scythe-hero::after {
    display: none;
  }
  .product-showcase h1 {
    font-size: 48px !important;
  }

  .product-showcase h3 {
    font-size: 20px !important;
  }

  .product-showcase .mower-3d-view .v360-image-canvas {
    background: url('/3d/drag-me-dark-mobile.png') 30px 10px  no-repeat !important;
  }
  
  .pricing {
    flex-wrap: wrap;
  }
  .reservation-form .pricing .btn, .vip-invitation .pricing .btn {
    width: 100% !important;
    margin-top: 20px !important;
  }
  .stage-one select {
    margin-bottom: 20px;
  }

  .slider {
    width: 80vw;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 989px){
  .callout h2 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .callout p {
    margin-top: 5px;
    font-size: 16px;
  }

  .get-scythe-hero ul.features {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 989px){
  .callout h2 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .callout p {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 990px) {
  .callout h2 {
    font-size: 22px;
    margin-bottom: 0;
  }
  .callout p {
    font-size: 16px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 1200px) {
  .callout h2 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .callout p {
    font-size: 18px;
    margin-top: 5px;
  }
}



.get-scythe-hero .callout h2 span {
  white-space: nowrap;
}
.callout h2 svg {
  margin-right: 15px;
  min-width: 42px;
}


.grey-bg {
  background: #ECF0F4;
}
.white-bg {
  background: #ffffff;
}

.product-showcase {
}

.product-showcase h1 {
  font-size: 72px;
  font-weight: bold;
  text-align: center;
}

.product-showcase h3 {
  font-weight: 300;
  text-align: center;
}

.product-showcase .v360-image-canvas {
  background: url('/3d/drag-me-dark.png') right 100px no-repeat;
}


.product-showcase .threesixty {
  /* text-align: center; */
  /* width: 800px; */
}

.product-specs {
}

.section-header h2 {
  font-weight: bold;
}

.section-header {
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.product-specs ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 25px;
  /* display: flex; */
  /* flex-wrap: wrap;
  align-items: top; */
}

.product-specs ul li {
  padding: 25px;
  border-radius: 10px;
  font-size: 18px;
  transition: background 0.3s ease;
}

.product-specs li:nth-child(4n) {
    /* flex-basis: 100%; */
}


/* .chop {
  width: 350px;
} */

.product-specs li h6 {
  font-size: 18px;
  font-weight: 300;
}

.product-specs li:hover {
  background: #ECF0F4;
  cursor: pointer;
}

.pricing {
}

.prices {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.prices > li {
  -ms-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg);
  transform-origin: center;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px #D9DDE0;
  width: 100%;
}

.prices > li.green {
  color: #fff;
  background: #02B040;
  border: 5px solid #fff;
}

.prices > li h6 {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
}

.prices > li p {
  text-align: left;
  margin-bottom: 0;
}
.prices > li div {
  -ms-transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  transform: skewX(10deg);
}

.accordion__panel {
  background: #ffffff;
  padding: 25px;
  padding-left: 45px;
}
.accordion__item {
  /* padding: 25px; */
  margin-bottom: 25px;
}

.accordion__button {
  display: flex;
  vertical-align: middle;
  border-bottom: 1px solid #D9DDE0;
  background: #fff;
  padding: 25px;
}


.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    margin-top: 8px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

.reservation-form .reserve-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  font-size: 22px;
  align-self: center;
  vertical-align: middle;

}

.reserve-btn:disabled, .reserve-btn[disabled] {
  background: #00b140;
  outline: #00b140;
  border-color: #00b140;
}

span.spinner {
  display: inline-block;
  margin-right: 10px;
  padding-top: 4px;
}

.reservation-form h3 {
  font-size: 32px;
  font-weight: bold;
}
.reservation-form h6 {
  font-weight: 300;
  font-size: 18px;
}

.reservation-form label {
  color: #000;
}
.reservation-form input::placeholder {
  color: #d9d9d9;
}
.reservation-form .pricing {
  display: flex;
  align-items: center;
}
.reservation-form .pricing h6 {
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 15px;
}

.reservation-form .pricing .btn {
  width: 50%;
  font-size: 20px;
  padding: 10px;
  margin-top: 0;
}

.reservation-form .btn.disabled, .vip-invitation .btn.disabled{
  background: #999 !important;
  border: transparent;
}

.reservation-form .actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  margin-top: 50px;
}

.reservation-form .actions .back-btn {
  /* color: red !important;  */
  vertical-align: middle;
  align-items: center;
  display: flex;
  height: 60px;
  padding-left: 25px;
  padding-right: 25px;
}

.checkbox-invalid {
  padding-left: 20px;
}

.legal-checkbox {
  margin-left: 15px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  /* background: red; */
  padding: 5px;
}

.legal-checkbox.is-invalid {
  background: #f6ccd0;
  border: 1px solid #dc3545;
  border-radius: 3px;
}
.legal-checkbox label {
  margin: 0;
}

.legal-checkbox input {
  width: 20px;
  height: 20px;
  margin-left: 0px;
  color: yellow;
}

.legal-checkbox span {
  padding-left: 30px;
  line-height: 30px;
}

.slider {
  margin-top: 50px;
  height: 70px;
}

.confirmation-list {
  display: flex;
  flex-direction: row;
}

.confirmation-img { 
  position: relative;
  width: 100%;
  margin-bottom: 25px;
}

/* slider */
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 5px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #00b140;
}
.rc-slider-handle {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  cursor: -webkit-grab;
  bottom: -7px;
  cursor: grab;
  border-radius: 50%;
  border: 5px solid #FFFFFF;
  background-color: #00b140;
  outline-color: #00b140;
  touch-action: pan-x;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.23);
}



.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
  margin-top: 5px;
  font-size: 16px;
  padding-left: 5px;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -5px;
  margin-left: -4px;
  width: 15px;
  height: 15px;
  border: 2px solid #e9e9e9;
  background: #c4c4c4;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #00b140;
  background-color: #00b140;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}


/* .scythe-modal {
  width: 80%;
  max-width: 1024px;
  top: 40%;
  left: 50%;
  right: auto;
  bottom: auto;
  padding: 0;
  transform: translate(-50%, -40%);
  border-radius: 5px;
  border: 0;
  z-index: 2001;
  position: fixed;
} */


.legal-modal .scythe-modal-content {
  overflow: scroll;
  height: 80vh;
  background: #fff;
  padding: 25px;
}

.reservation-confirmation {
  margin-top: 60px;
}

.reservation-confirmation h3 {
  font-weight: bold;
}

.confirmation-hero h1 {
  font-weight: bold;
  font-size: 4rem;
}

.confirmation-hero h3 {
  font-weight: 300;
}

.green-bg {
  background: #00b140;
  color: #fff;
}

.confirmation-info {
  padding: 25px;
}
.confirmation-info h3 {
  font-size: 2rem;
  font-weight: bold;
}

.confirmation-info dt {
  font-size: 1.5rem;
}

.confirmation-info dd {
  font-size: 1.2rem;
  font-weight: normal;
}

.social-links {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 50px;
}

.terms-modal h2 {
  font-weight: bold;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.terms-modal .scythe-modal-content {
  padding-left: 100px;
  padding-right: 100px;
}

.terms-modal .scythe-modal-header {
  justify-content: space-between;
  margin-bottom: 25px;
}

.calendly-btn {
  margin-bottom: 50px;
}
.calendly-btn span{
  -ms-transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  transform: skewX(10deg);
  transform-origin: center;
  display: inherit;
}

.vip-invitation {
  background: #fff;
  height: 100vh;
}

@media (min-height: 0px) and (max-height: 820px){
  .vip-invitation {
    height: 100% !important;
  }
}


.vip-invitation .bg-img {
  background: url('/img/modal-bg.jpg') top center no-repeat;
  background-size: cover;
  min-height: 100%;
}

@media (min-height: 741px){
  .vip-invitation > .row {
    height: 100%;
  }
}


.vip-invitation .content-side {
  /* padding-top: 50px;
  padding-left: 25px;
  padding-right: 50px; */
}
.vip-invitation .customer-name {
  display: flex;
  width: 100%;
  vertical-align: middle;
  flex-wrap: wrap;
}

.vip-invitation .customer-name .plus {
  font-size: 96px;
  font-weight: 900;
  line-height: 80px;
}

.vip-invitation .customer-name h1 {
  font-size: 72px;
  font-weight: 900;
  line-height: 80px;
}

.vip-invitation .actions  {
  /* justify-content: center; */
  text-align: center;
}

.vip-invitation .legal-checkbox {
  width: 100%;
  margin: 0;
}

.vip-invitation .actions .btn {
  width: 100%;
  font-size: 24px;
  padding: 15px;
  margin-bottom: 50px;
}

.vip-invitation h3 {
  font-weight: bold;
  margin-top: 50px;
}

.vip-invitation .mower-3d-view .v360-image-canvas {
  background: url('/3d/drag-me-dark-mobile.png') right 10px  no-repeat !important;
}

.vip-invitation .exploded {

}

.vip-invitation .reservation-info {
}

.vip-invitation .reservation-info .tilt-card {
  -ms-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg);
  transform-origin: center;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  /* box-shadow: 0 0 10px #D9DDE0; */

}

.vip-invitation .reservation-info .tilt-card.green {
  color: #000;
  /* background: #8BC19E; */
  /* background: rgba(0, 177, 64, 0.5); */
  background: #e1e1e1;
  border: 5px solid #fff;
}

.vip-invitation .reservation-info .tilt-card >div {
  -ms-transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  transform: skewX(10deg);
}

.vip-invitation .reservation-info h4 {
  font-size: 24px;
  font-weight: normal;
}

.vip-invitation .reservation-info .robot-count {
  font-size: 42px;
  font-weight: 900;
}

.due-today-price {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  vertical-align: middle;
  height: 100%;
}
.due-today-price h6 {
  text-transform: uppercase;
  color: #999;
}

.due-today-price h3 {
  font-size: 36px;
  margin: 0;
}

.vip-invitation .reservation-info .label {

}

.loading-screen {
  text-align: center;
  margin-top: 30vh;
}